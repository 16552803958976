import React, { useState } from 'react';

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    teamMember: '',
    linkedIn: '',
    titleInstitution: '',
    dataTypes: '',
    usageFrequency: '',
    similarToolExperience: '',
    feedback: false,
    specificDatasets: ''
  });

  const [status, setStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Sending...');
    setStatusClass('sending');
  
    fetch('https://api.withmantis.com/api/betauser/', { // Replace with your endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(async (response) => {
        if (response.ok) {
          setStatus('Signup success! Please visit alpha.withmantis.com to access the beta version.');
          setStatusClass('success');
          setFormData({
            name: '',
            email: '',
            teamMember: '',
            linkedIn: '',
            titleInstitution: '',
            dataTypes: '',
            usageFrequency: '',
            similarToolExperience: '',
            feedback: false,
            specificDatasets: ''
          });
        } else {
          const data = await response.json();
          throw new Error(data.error || 'An unknown error occurred.');
        }
      })
      .catch((error) => {
        setStatus('An error occurred: ' + error.message);
        setStatusClass('error');
      });
  };  

  // Styles
  const styles = {
    form: {
      maxWidth: '500px',
      margin: '50px auto',
      padding: '30px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
      color: '#000'
    },
    input: {
      width: '100%',
      padding: '12px 20px',
      margin: '8px 0',
      boxSizing: 'border-box',
      borderRadius: '4px',
      border: '1px solid #ccc',
      color: '#000'
    },
    textarea: {
      width: '100%',
      padding: '12px 20px',
      margin: '8px 0',
      boxSizing: 'border-box',
      borderRadius: '4px',
      border: '1px solid #ccc',
      color: '#000',
      height: '100px'
    },
    button: {
      width: '100%',
      backgroundColor: '#28a745',
      color: '#fff',
      padding: '14px 20px',
      margin: '8px 0',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px'
    },
    status: {
      padding: '10px',
      marginTop: '20px',
      borderRadius: '4px',
      textAlign: 'center',
      color: '#000'
    },
    sending: {
      backgroundColor: '#ffc107',
      color: '#fff'
    },
    success: {
      backgroundColor: '#28a745',
      color: '#fff'
    },
    error: {
      backgroundColor: '#dc3545',
      color: '#fff'
    }
  };

  return (
    <form style={styles.form} onSubmit={handleSubmit}>
      <h2 style={{ textAlign:'center' }}>Sign Up for Beta Testing</h2>
      
      <label>Name:</label>
      <input
        style={styles.input}
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
       />

       <label>Google Authentication Email:</label>
       <input
         style={styles.input}
         type="email"
         name="email"
         value={formData.email}
         onChange={handleChange}
         required
       />

       <label>Do you know a member of the Mantis team? If so, who and how did you connect?</label>
       <input
         style={styles.input}
         type="text"
         name="teamMember"
         value={formData.teamMember}
         onChange={handleChange}
         required
       />

       <label>Provide a link to LinkedIn page or webpage:</label>
       <input
         style={styles.input}
         type="url"
         name="linkedIn"
         value={formData.linkedIn}
         onChange={handleChange}
         required
       />

       <label>What is your title and institution?</label>
       <input
         style={styles.input}
         type="text"
         name="titleInstitution"
         value={formData.titleInstitution}
         onChange={handleChange}
         required
       />

       <label>What data types are you planning to map with Mantis?</label>
       <input
         style={styles.input}
         type="text"
         name="dataTypes"
         value={formData.dataTypes}
         onChange={handleChange}
         required
       />

       <label>How often do you think you will be using Mantis?</label>
       <input
         style={styles.input}
         type="text"
         name="usageFrequency"
         value={formData.usageFrequency}
         onChange={handleChange}
         required
       />

       <label>Have you tried using a similar tool before? If so, what worked or didn’t work for your needs?</label>
       <textarea
         style={styles.textarea}
         name="similarToolExperience"
         value={formData.similarToolExperience}
         onChange={handleChange}
         required
       />

       <label>Are you planning to work with any specific datasets that require particular handling or privacy considerations?</label>
       <textarea
          style={styles.textarea}
          name="specificDatasets"
          value={formData.specificDatasets}
          onChange={handleChange}
          required
        />

        <button type="submit" style={styles.button}>
          Submit
        </button>

        {status && (
          <div
            style={{
              ...styles.status,
              ...(statusClass === 'sending' && styles.sending),
              ...(statusClass === 'success' && styles.success),
              ...(statusClass === 'error' && styles.error)
            }}
          >
            {status}
          </div>
        )}
     </form>
   );
};

export default SignUpForm; // sign up